<template>
  <div class="login-box">
    <img
      class="login-box-cover-img"
      src="../assets/image/woyaoyou_bg_login@2x.png"
    />
    <div class="login-main">
      <div>
        <div class="text-center">
          <img
            v-if="siteInfo != null"
            style="width: 162px; height: 92px"
            :src="siteInfo.logo_white_account_login"
          />
        </div>
        <div class="login-form-box">
          <a-tabs v-model="type">
            <a-tab-pane key="1" :tab="AccountPasswordLogin">
              <div class="login-input-item flex alcenter space">
                  <!--<a-select
                          v-model="area_code"
                          placeholder="Please select"
                          style="width: 65px"
                  >
                      <a-select-option v-for="item in range" :key="item.value" :value="item.value">{{item.text}}</a-select-option>
                  </a-select>-->

                <a-input
                  v-model="form.mobile"
                  :prefix="phoneNumber"
                  :maxLength="11"
                  class="mobile"
                ></a-input>
              </div>
              <div class="login-input-item mt24 flex alcenter space">
                <a-input
                  type="password"
                  v-model="form.pwd"
                  :prefix="password"
                  class="mobile"
                ></a-input>
              </div>
              <div class="mt32 login-btn">
                <a-button
                  style="width: 100%"
                  @click="loginAct2"
                  type="primary"
                  >{{ $t("login.SignInNow") }}</a-button
                >
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" :tab="AuthenticationCodeLogin" force-render>
              <div class="login-input-item flex alcenter space">
                  <a-select
                          v-model="form.area_code"
                          placeholder="Please select"
                          style="width: 65px"
                          @change="area_change"
                  >
                      <a-select-option v-for="item in range" :key="item.value" :value="item.value">{{item.text}}</a-select-option>
                  </a-select>
                  <a-input
                  v-model="form.mobile"
                  :prefix="phoneNumber"
                  :maxLength="11"
                  class="mobile"
                ></a-input>
              </div>
              <div class="login-input-item mt24 flex alcenter space">
                <a-input
                  v-model="form.code"
                  class="code"
                  :maxLength="6"
                  :prefix="VerificationCode"
                ></a-input>

                <countdown-djs
                  :area_code="form.area_code"
                  :mobile="form.mobile"
                  :code="code"
                  :type="'login'"
                ></countdown-djs>
              </div>
              <div class="mt32 login-btn">
                <a-button
                  style="width: 100%"
                  @click="loginAct"
                  type="primary"
                  >{{ $t("login.SignInNow") }}</a-button
                >
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countdownDjs from "../components/countdown/djs.vue";
export default {
  components: {
    countdownDjs,
  },
  data() {
    return {
      type: "1",
      loading: false,
      code: "",
      AccountPasswordLogin: this.$t("login.AccountPasswordLogin"),
      AuthenticationCodeLogin: this.$t("login.AuthenticationCodeLogin"),
      phoneNumber: this.$t("login.phoneNumber"),
      password: this.$t("login.password"),
      VerificationCode: this.$t("login.VerificationCode"),
      GetVerificationCode: this.$t("login.GetVerificationCode"),
      form: {
        area_code: 65,
        mobile: "",
        code: "",
        pwd: "",
      },
      siteInfo: null,
        range: [
            {'value':65,'text':'+65'},
            {'value':60,'text':'+60'},
            {'value':86,'text':'+86'},
        ],
    };
  },
  created() {
    if (localStorage.getItem("account-token")) {
      this.$router.push("/mendian");
    } else {
      this.getSiteInfo();
      this.getCode();
    }
  },

  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    area_change(e){
      this.form.area_code = e;
    },
    getSiteInfo() {
      this.$http
        .api("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getCode() {
      this.$http
        .api("platform/account/getCode", {})
        .then((res) => {
          this.code = res.code;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    loginAct() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/login", {
          mobile: this.form.mobile,
          code: this.form.code,
          token: this.code,
        })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("account-token", res.auth_token);
          this.$message.success(this.$t("login.LoginSucceeded"), 1, () => {
            this.$router.push("/mendian");
          });
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    loginAct2() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/pwdLogin", {
          mobile: this.form.mobile,
          pwd: this.form.pwd,
        })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("account-token", res.auth_token);
          this.$message.success(this.$t("login.LoginSucceeded"), 1, () => {
            this.$router.push("/mendian");
          });
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        if (this.type == "1") {
          this.loginAct2();
        } else {
          this.loginAct();
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style>
.login-box {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #4772ff 0%, #5851ff 100%);
  position: relative;
}
.login-main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.login-box-cover-img {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
.login-form-box {
  width: 420px;
  height: 362px;
  background: #ffffff;
  box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
  border-radius: 24px;
  margin-top: 36px;
  padding: 48px 40px;
}
.login-input-item {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #dcdfe6;
  padding: 0 24px;
}
.login-input-item .ant-input {
  border: none;
  font-size: 16px;
}
.login-input-item .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 66px;
}
.login-input-item .mobile {
  width: 100%;
}
.login-input-item .code {
  width: 200px;
}
.login-input-item .ant-input-affix-wrapper .ant-input-prefix {
  color: #a6aec2;
  font-size: 16px;
}
.login-input-item .ant-input:focus {
  border: none;
  box-shadow: none;
}
.login-input-item .area_code{
    width: 70px;
}
.login-btn .ant-btn {
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
}
</style>
