<template>
  <div
    style="cursor: pointer"
    class="ft16 ftw500 cl-placeholder"
    @click="djsIng"
    :style="djs == t ? colorStyle : ''"
  >
    {{ getText }}
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
    area_code: {
      type: String,
      default: "65",
    },
    mobile: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "login",
    },
  },
  created() {
    let cache = localStorage.getItem(this.type + "_countdown")
      ? parseInt(localStorage.getItem(this.type + "_countdown"))
      : 0;
    if (cache > 0) {
      this.djs = cache;
      this.djsAct();
    }
  },
  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    localStorage.removeItem(this.type + "_countdown");
  },
  computed: {
    colorStyle() {
      if (this.color) {
        return "color:" + this.color + ";";
      } else {
        return "color:#4772FF";
      }
    },
    getText() {
      let txt = this.$t("login.GetVerificationCode");
      if (this.isDjs == true) {
        if (this.djs == this.t) {
          txt = this.$t("login.Reacquire");
        } else {
          txt = this.djs + this.$t("login.ResendAfter");
        }
      }
      return txt;
    },
  },
  data() {
    return {
      isDjs: false,
      djs: 60,
      t: 60, //发送间隔时长
      timer: null,
    };
  },
  methods: {
    djsIng() {
      if (this.timer != null) return false;
      if (!/^\d+$/.test(this.mobile)) {
        this.$message.error("输入手机号");
        return false;
      }
      this.djsAct();
      this.loadCode();
    },
    loadCode() {
      //服务端请求写这里
      this.$http
        .api("platform/account/sms", {
          token: this.code,
          area_code: this.area_code,
          mobile: this.mobile,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    djsAct() {
      if (this.timer != null) return false;
      this.djs = this.djs - 1;
      this.isDjs = true;
      this.timer = setInterval(() => {
        if (this.djs <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.djs = this.t;
        } else {
          this.djs = this.djs - 1;
          localStorage.setItem(this.type + "_countdown", this.djs);
        }
      }, 1000);
      //静默操作
    },
  },
};
</script>

<style>
</style>
